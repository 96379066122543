.formContainer {
    color: #000;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    padding-bottom: 120px;
}

.licenseNumber {
    display: flex;
    position: absolute;
    font-size: 15px;
    margin-top: 10px;
}
.fullWidthInput {
    width: 100%;
}

.errorText {
    color: #fe671f;
}

.inputSection {
    background-color: #ffffff;
    padding: 10px;
}

.card {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.saveChangesButton {
    position: fixed;
    bottom: 4%;
    right: 4%;
}

h5 {
    text-align: left;
}


h6 {
    text-align: left;
}

label {
    text-align: left;
}

input{
    color: black !important;
}

.mapContainer{
    border: 2px solid gray;
    height: 90%;
}

/* .titleTag{
    position: fixed;

} */

.attachmentTile{
    border-style: none;
    border-width: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-right: 25px;
}

.noOverflow{
    overflow:hidden;
    height: 10%;
}

.hideInPrint{
    width: 100%;
}

.showInPrint {
    display: none !important;
}

.activityAreaPics {
    height: 300px;
}

.row {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
}

.column {
    -ms-flex: 50%;
    /* IE10 */
    flex: 50%;
    max-width: 50%;
    /* padding: 0 4px; */
}


/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}

@media print {

    /* HIDE UNWANTED ELEMENTS */
    body::before,
    .saveChangesButton,
    .hideInPrint,
    #logo,
    .final-result::before,
    .info::before,
    .final-result::after,
    .info::after,
    .final-result h2,
    .info h2 {
        display: none;
    }

    .activityAreaPics {
        page-break-inside: avoid !important;
        height: 300px;
    }

    .gridLayoutPos {
        position: unset !important;
    }


    .showInPrint {
        width: 100%;
        display: block !important;
    }


    /* GLOBAL RESET */
    /* body {
      font-family: Georgia, serif;
      font-size: 16pt;
      line-height: 24pt;
      background: none;
      color: #000;
    } */


    /* LAYOUT RESET */
    .final-result,
    .info,
    section,
    .card footer {
        background: none;
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
        color: #000;
    }

    section:first-child::before {
        border: none;
    }

    /* SPECIFICS */
    /* h1,h2,h3,h4,h5,h6 {
      color: #000;
      font-size: 24pt;
      line-height: 30pt;
      margin: 0;
    } */

    h1 {
        font-size: 50pt;
        margin: 0 0 20pt;
        text-align: center;
        width: 100%;
        transform: rotate(0);
    }

    h1 small {
        font-size: 24pt;
        color: #000;
        margin: 0 0 10pt;
    }

    .info p {
        color: #000;
    }

    a:link {
        margin: 0;
    }

    a::after {
        content: " [" attr(href) "]";
    }

    footer a:link,
    footer a:visited {
        color: #000;
        background: none;
        padding: 0;
    }

    .mapContainer {
        border: 2px solid gray;
        display: block !important;
        page-break-inside: avoid !important;
    }

    img {
        page-break-inside: avoid !important;
    }

    /* .pageBreak	{ display: block; page-break-before: always; } */

}
