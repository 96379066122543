.formContainer {
    color: #000;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    padding-bottom: 120px;
}

.fullWidthInput {
    width: 100%;
}

.errorText {
    color: #fe671f;
}

.inputSection {
    background-color: #ffffff;
    padding: 10px;
}

.card {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.saveChangesButton {
    position: fixed;
    bottom: 4%;
    right: 4%;
}

h5 {
    text-align: left;
}


h6 {
    text-align: left;
}

label {
    text-align: left;
}

input{
    color: black !important;
}

.fullscreenImageContainer{
    background-color: white;
    overflow: auto;
}
.closeButton{
    position: absolute;
    top: 2%;
    left: 2%;
}

@media print {

    /* HIDE UNWANTED ELEMENTS */
    body::before,
    .saveChangesButton,
    .hideInPrint,
    #logo,
    .final-result::before,
    .info::before,
    .final-result::after,
    .info::after,
    .final-result h2,
    .info h2 {
        display: none;
    }

    .activityAreaPics {
        page-break-inside: avoid !important;
        height: 300px;
    }

    .gridLayoutPos {
        position: unset !important;
    }


    .showInPrint {
        width: 100%;
        display: block !important;
    }


    /* GLOBAL RESET */
    /* body {
      font-family: Georgia, serif;
      font-size: 16pt;
      line-height: 24pt;
      background: none;
      color: #000;
    } */


    /* LAYOUT RESET */
    .final-result,
    .info,
    section,


    section:first-child::before {
        border: none;
    }

    /* SPECIFICS */
    /* h1,h2,h3,h4,h5,h6 {
      color: #000;
      font-size: 24pt;
      line-height: 30pt;
      margin: 0;
    } */

    h1 {
        font-size: 50pt;
        margin: 0 0 20pt;
        text-align: center;
        width: 100%;
        transform: rotate(0);
    }

    h1 small {
        font-size: 24pt;
        color: #000;
        margin: 0 0 10pt;
    }

    .info p {
        color: #000;
    }

    a:link {
        margin: 0;
    }

    a::after {
        content: " [" attr(href) "]";
    }

    footer a:link,
    footer a:visited {
        color: #000;
        background: none;
        padding: 0;
    }

    .mapContainer {
        border: 2px solid gray;
        display: block !important;
        page-break-inside: avoid !important;
    }

    .activity-area-pictures img {
        page-break-inside: avoid;
        /* Avoid breaking inside images */
    }

    img {
        page-break-inside: avoid !important;
    }

    body {
        -webkit-print-color-adjust: exact;
        counter-reset: page;
        /* Initialize the counter for page numbers */
    }

    .page-content:before {
        counter-increment: page;
        content: "Page " counter(page);
        display: block;
        /* text-align: center; */
        font-size: 15px;
        margin-top: 10px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
    }

    .page-break {
        display: none;
        /* Hide page-break div */
    }
}
.header{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 20px;
}
.header img {
    position: absolute;
    left: 0;
}
.headerSection {
    text-align: center;
    bottom:20px;
}