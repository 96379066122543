.listContainer{
    color: #000;
    display:flex;
    flex-direction: column;
    max-width: 1536px;
    width:100%;
}

.noProjectsText{
    text-align: center;
}

.myProjectsTitle{
    color: #000000;
}

.projectTile{
    border-color: #385988;
    border-style: solid;
    border-width: thin;
    padding: 0;
    width: 100%;
    height: 100%;
    /*margin-right: 25px;*/
}

.projectTile .bp3-button .bp3-button-text {
    flex: 1
}

.thumbnailContainer{
    background-color: #e3e3e3;
    margin-right: 10px;
}

.badgeContainer{
    position:relative;
    left:-12.5px;
}

.thumbnail{
width: 100%;
    height: 100%;
    object-fit: cover;
}
