.pickerCard{
    color: black;
    margin: 15px;
}

.pickerCardTitle{
    margin:0;
}

.assignedTasksContainer{
    padding: 10px;
}

.assignedTasksList{
    margin-top: 10px;
}

.fullWidthDivider{
    width: 100%;
    margin-bottom: 20px;
}

.numericInput{
    width: 100%;
}

.numericInput .bp3-input-group{
    width: 81%;
}

.taskText{
    text-align: center;
}

.estimationCard {
    position: fixed;
    bottom: 7%;
    left: 4%;
}

.header {
    color: black;
    font-size: 16px !important;
    font-family: inherit;
    border: 2px solid black;
    margin-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
}
