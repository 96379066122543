.closeButton{
    z-index: 10;
    position: absolute;
    top: 2%;
    left: 2%;
}

.previewControls{
    background-color: white;
}

.previewImage{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.previewContainer{
    background-color: white;
}
