.dashboardView{
    width: 100%;
    height: 100%;
}

.gridItem{
    margin:50px;
}

.gridButton{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
}

.projectsButton{
    background-color: #00afd7;
}

.newProjectsButton{
    background-color: #43b02a;
}

.vendorsButton{
    background-color: #fe671f;
}

.teamsButton{
    background-color: #385988;
}

.faIcon{
    margin:30px;
}

.buttonLabel{
    color: #ffffff;
}

.border{
    margin: 0;
    border-bottom-width: 5px;
    border-bottom-color: #385988;
}
