.faIconContainer{
    width: 100%;
    height: 100%;
}

.faIcon{
    width: 50%;
    height: 50%;
}

.faText{
    z-index: 1;
    color: #00afd7;
    font-weight: 600;
    margin: 0 auto;
    padding-top: 3px;
    font-size: 20px;
    position: relative;
}

.appStartedText{
    font-size: 13px;
    color: white;
    text-shadow:
            -2px -2px 0 #00afd7,
            2px -2px 0 #00afd7,
            -2px 2px 0 #00afd7,
            2px 2px 0 #00afd7;
}

.appSubmittedText{
    font-size: 10px;
    color: white;
    text-shadow:
            -2px -2px 0 #00afd7,
            2px -2px 0 #00afd7,
            -2px 2px 0 #00afd7,
            2px 2px 0 #00afd7;
}

.faDynamicText{
    font-size: calc(3px + 2vmin);
}

.hfhi_logo {
    width: 50px;
    height: 50px;
    z-index: 1;
}