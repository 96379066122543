/* CustomToggleSwitch.css */
.custom-toggle-switch {
    width: 73px;
    height: 30px;
    background-color: #ddd;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    margin-left: 30px;
    /* margin-bottom: 35px; */
  }
  
  .custom-toggle-switch.checked {
    background-color: #106ba3;
  }
  
  .toggle-slider {
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 5%;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    transition: transform 0.3s;
  }
  
  .custom-toggle-switch.checked .toggle-slider {
    transform: translateX(40px) translateY(-50%);
  }
  
  .toggle-labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    color: #fff;
  }
  
  .yes-label,
  .no-label {
    flex: 1;
    text-align: center;
    padding: 5px;
  }
  