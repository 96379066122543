.closeButton{
    z-index: 10;
    position: absolute;
    top: 2%;
    left: 2%;
}

.previewControls{
    background-color: white;
}

.previewImage{
    width: 60%;
    height: auto;
    padding-right: 50px;
    /* margin-left: 20%; */
    object-fit: contain;
}

.previewContainer{
    background-color: white;
    overflow: auto;
    justify-content: center
}
.cropContainer{
    position: 'relative';
    width: '100%';
    height: 200;
    background: '#333';
    
    }